const nodeEnv = process.env.NODE_ENV as "production" | "development";

const connectFirebaseFunctionsEmulator =
  process.env.NEXT_PUBLIC_CONNECT_FIREBASE_FUNCTIONS_EMULATOR === "true";

export const ENVIRONMENT = {
  nodeEnv,
  name: process.env.NEXT_PUBLIC_ENVIRONMENT as string,
  version: process.env.NEXT_PUBLIC_VERSION as string,
  gitHash: process.env.NEXT_PUBLIC_GIT_HASH || "",
  landingPageUrl: process.env.NEXT_PUBLIC_LANDING_PAGE_URL as string,
  firebaseRegion: process.env.NEXT_PUBLIC_FIREBASE_REGION as string,
  isUsingProductionFirebaseLocally:
    nodeEnv !== "production" && !connectFirebaseFunctionsEmulator,
  firebaseConfig: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN as string,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET as string,
    messagingSenderId: process.env
      .NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID as string,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID as string,
    connectFirebaseFunctionsEmulator,
  },
  sentry: {
    dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: (process.env.SENTRY_ENVIRONMENT ||
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT) as "production" | "staging",
  },
  hubspot: {
    companyBaseUrl: process.env.NEXT_PUBLIC_HUBSPOT_COMPANY_BASE_URL as string,
    contactBaseUrl: process.env.NEXT_PUBLIC_HUBSPOT_CONTACT_BASE_URL as string,
  },
};
