import * as Sentry from "@sentry/nextjs";
import { Options } from "@sentry/types";
import { Organization, User } from "@snubes/snubes-types";
import { ENVIRONMENT } from "../Application/consts/ENVIRONMENT";
import { getUserName } from "../Users/helpers/getUserName";

export function setupSentry(options?: Options) {
  Sentry.init({
    dsn: ENVIRONMENT.sentry.dsn,
    environment: ENVIRONMENT.sentry.environment,
    tracesSampleRate: 1.0,
    ...options,
  });
}

export function setupSentryUser(user?: User) {
  if (user) {
    Sentry.setUser({
      id: user.id,
      username: getUserName(user),
      email: user.email,
    });
  } else {
    Sentry.setUser(null);
  }
}

export function setupSentryOrganization(organization?: Organization) {
  Sentry.setTag("organization_id", organization?.id || null);
  Sentry.setTag("organization_name", organization?.name || null);
}
